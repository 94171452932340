<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :width="1100"
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="划拨"
      @ok="handleSubmit"
    >
      <a-row>
        <a-form
          class="simiot-compact-form"
          :form="form"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 15 }"
          @submit="handleSubmit"
        >
          <a-col :span="12">
            <a-form-item
              :wrapper-col="{ span: 24 }"
            >
              <common-card-input
                :rows="20"
                @change="handleIccidsChange"
                v-decorator="['iccids', {
                  rules: [{ validator: checkIccids }],
                }]"
              />
            </a-form-item>

          </a-col>

          <a-col :span="12">
            <a-form-item label="项目卡数(张)">
              <span>{{ data.item_cards_count }}</span>
            </a-form-item>

            <a-form-item label="已划拨卡数(张)">
              <span>{{ data.allocated_cards_count }}</span>
            </a-form-item>

            <a-form-item label="输入卡号总数(张)">
              <span>{{ input_cards_count }}</span>
            </a-form-item>

            <a-form-item label="备注">
              <a-textarea
                :rows="3"
                v-decorator="['remark', {
                  rules: [
                    { max: 200, message: '最多200个字符' },
                  ]
                }]"
              />
            </a-form-item>
          </a-col>
        </a-form>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import { allocateCardsToTopAgent, findAgentPurchaseItemAllocationInfo } from '@/api/agent_purchase_item'
import CommonCardInput from '@/components/CardInput/Common'

export default {
  name: 'AllocateCardsToTopAgent',
  components: { CommonCardInput },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    agentPurchaseItemId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: {
        item_cards_count: 0, // 项目卡数
        allocated_cards_count: 0 // 已划拨卡数
      },
      input_cards_count: 0, // 输入卡号总数
      form: this.$form.createForm(this, { name: 'allocate' }),
      submitting: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findAgentPurchaseItemAllocationInfo(this.agentPurchaseItemId).then((res) => {
        this.data = res.data
      })
    },

    checkIccids(rule, value, callback) {
      if (!value || !value.inputValue) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请输入卡号')
        return
      }

      if (value.validateStatus === 'error') {
        callback(value.errorMsg)
        return
      }
      if (this.input_cards_count + this.data.allocated_cards_count > this.data.item_cards_count) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('卡数超过项目要求')
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    handleIccidsChange(e) {
      this.input_cards_count = e.cardsCount - e.duplicateCardsCount
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            iccids: values.iccids.data
          }
          allocateCardsToTopAgent(this.agentPurchaseItemId, data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
              this.$success({
                title: '提交成功',
                content: (
                  <div>
                    <p>操作成功，具体操作结果请至 【系统查询】-【批量操作查询】 查看</p>
                  </div>
                )
              })
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>

